<template>
    <div>
        <div>
            <el-carousel :interval="4000" height="4rem">
                <el-carousel-item v-for="(item,index) in imgs" :key="index">
                    <img :src="item" alt="" width="100%" height="100%">
                </el-carousel-item>
            </el-carousel>
        </div>

        <div class="page_wrap">
            <div class="page_content" style="width:90%">
                <div class="detailPage-bar"></div>
                <div class="detailPage-title" >产品实物图</div>
                <div class="page_flex" style="padding:0.4rem 0">
                    <div class="imgWrap">
                        <div class="bigImage" :style="{ 'backgroundImage' : `url(${ imgSrc })`}">
                                <span v-if="!imgSrc" class="emptyImg">暂无图片</span>
                        </div>
                        <div class="smallImg">
                            <div class="smallImg-list-item" v-for="(item,index) in details.imgList" :key="index" :style="{ 'backgroundImage' : `url(${ item.src })`}" @click="changePic(item)">
                            </div>
                        </div>
                    </div>
                    <div class="productIntruce" id="productIntruce">
                        {{ details.productIntorduce || '暂无产品简介'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="page_wrap">
            <div class="page_content" style="width:70%">
                <div class="detailPage-bar" style="margin:0;"></div>
                <div class="specifications-title">技术规格</div>
                <div class="specifications-table">
                    <table>
                        <tr v-for="item in details.data" :key="item.index">
                            <td class="table-label">{{ item.label }}</td>    
                            <td class="table-info">{{ item.info }}</td>    
                        </tr>
                    </table>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
/* eslint-disable */
export default {
    data(){
        return {
            imgSrc:'',
            imgs:[
                require('@/assets/images/banner2.png')
            ],
            details:{},
        }
    },
    methods:{
        changePic(item){
            this.imgSrc = item.src
        },
    },
    created(){
        this.details = JSON.parse(this.$route.query.info)
        console.log(this.details)
        if(this.details.imgList && this.details.imgList.length>0){
            this.imgSrc = this.details.imgList[0].src
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
}
</script>
<style lang="scss" scoped>
.emptyImg{
    font-size: 0.2rem;
    position: relative;
    top:40%;left:50%;
}
    table{
        margin:0.2rem auto;
        width:100%;

    }
    td{
        border: 1px solid #DCDFE6;
        padding:0 0.3rem;
    }
    .table-header{
        height:0.5rem;
        line-height: 0.5rem;
        width: 100%;
        background-color: #DCDFE6;
    }
    .table-label{
        height: 0.5rem;
        line-height:0.5rem;
        background: #f7f7f7;
        font-weight: 600;
        color:#303133;
    }
    
    .detailPage-title{
        font-size: 0.24rem;
        text-align: center;
        width: 100%;
        height: 0.5rem;
        line-height: 0.5rem;
        color:#333;
        font-weight: 500;
    }
    .detailPage-bar{
        width: 0.8rem;
        height: 1px;
        border: 1px solid #0061E3;
        background: #0061E3;
        margin:0.08rem auto;
        margin-top:0.3rem;
    }
    .imgWrap{
        width: 45%;
        max-width: 8rem;
        height: 4rem;
        border: 1px solid #E0E0E0;
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .bigImage{
        background-repeat: no-repeat;
        background-size: contain;
        background-position:center;
        width: 78%;
        height: 100%;
    }
    .smallImg{
        width: calc(22% - 0.2rem);
        height: calc(100% - 0.2rem);
        // position: absolute;
        // right:0;top:0;
        padding:0.1rem;
        display: flex;
        justify-content: space-around;
        flex-direction: column;
    }
    .smallImg-list-item{
        background-repeat: no-repeat;
        background-size: contain;
        background-position:center;
        border: 1px solid #E0E0E0;
        width: 100%;
        height: 30%;
        cursor: pointer;
    }
    .smallImg-list-item:hover{
        transform: scale(1.1);
        box-shadow:0px 0px 5px #888888;

    }
    .productIntruce{
        font-size: 0.14rem;
        color:#606266;
        width:52%;
        margin-left:8%;
        line-height: 0.3rem;
        text-indent: 0.3rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .specifications-title{
        font-size: 0.24rem;
        margin:0.14rem 0;
    }
</style>